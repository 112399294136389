.AboutUs{
    color: rgb(0, 0, 0);
    font-size: 20px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    text-align: justify;
}

.AboutUs h3 {
    font-weight: 700;
    font-family: 'Familjen Grotesk', sans-serif;
}

.AboutUs b {
    color: #888;
}

.AboutUs span {
    font-weight: 400;
    color: #888;
}


.partners{
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.partners img {
    width: 10vw;
}

.contactInformation{
    width:50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: 20px;
    line-height: 30px;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 1300px) {
    .partners{
        width: 40vw;
        flex-direction: column;
    }

    .partners img {
        width: 100%;
    }
}