.ProjectHeader {
    width: 93vw;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 10px;
    margin: 0;
}