

.squares{
    width: 98.2%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
}

.square{
    position: relative;
    display: flex;
    width: 31.8vw;
    height: 20vw;
    background-size: cover;
    background-repeat: no-repeat;
    transition: 0.3s;
    margin-top: 1vw;
}

.square::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Kolor przyciemnienia, używamy rgba() dla przezroczystości */
  opacity: 0; /* Ustawiamy początkową przezroczystość na zero */
  transition: 0.3s; /* Dodajemy płynne przejście */
}

.square:hover::before {
    opacity: 1; /* Przy hoverze zmieniamy przezroczystość na pełną (1), powodując efekt przyciemnienia */
  }

  .text {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white; /* Kolor tekstu na biały */
    font-size: 20px; /* Dostosuj rozmiar tekstu do swoich potrzeb */
    opacity: 0; /* Ustawiamy początkową przezroczystość na zero */
    pointer-events: none; /* Wyłączamy zdarzenia dla tekstu, aby nie blokować hovera na elemencie */
  }
  
  .square:hover .text {
    opacity: 1;
  }

  @media screen and (max-width: 1300px) {
    .square{
      width: 100%;
      height: 50vh;
      background-position: center;
    }
  }
