.Offer{
    color: rgb(0, 0, 0);
    font-size: 20px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    text-align: justify;
}


.Offer span {
    font-weight: 400;
    color: #888;
}

.Offer ul {
    list-style-type: none;
}

.Offer ul li {
    padding-bottom: 10px;
    font-weight: 400;
}