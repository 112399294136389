.Cooperation{
    color: rgb(0, 0, 0);
    font-size: 20px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    text-align: justify;
}

.Cooperation h3 {
    font-weight: 700;
    font-family: 'Familjen Grotesk', sans-serif;
}

.Cooperation b {
    color: #888;
}

.Cooperation span {
    font-weight: 400;
    color: #888;
}

.exterrior {
    margin-left: auto;
    margin-right: auto;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 15px 0;
}

.exterrior img {
    width: 34.5vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 5px;
}

.interrior {
    width: 100%;
}

.interrior img {
    width: 100%;
}

.video {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin: 20px 0;
}

video {
    width: 100%;
}

@media screen and (max-width: 1300px) {
    .exterrior{
        flex-direction: column;
        align-items: center;
    }
    .exterrior img{
        width: 60vw;
        padding: 10px 0;
    }
}