.footer{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    color: #888;
    margin: 30px 0;
}

.socialsFooter{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.socialsFooter a {
    color: #888;
    padding: 0 10px;
}

.logo2 {
    width: 80px;
    position: absolute;
    right: 3vw;
    bottom: 20;
  }

footer{
    width: 100%;
    height: auto;
    text-align: center;
    padding: 10px 0 ;
}

footer span {
    display: flex;
}

footer a {
    color: rgb(0, 0, 0);
    display: inline;
}


@media screen and (max-width: 1300px) {
    .logoFooter img{
        width: 25%
    }
    footer{
        font-size: small;
    }
}

@media screen and (max-width: 600px) {
    .logoFooter img{
        width: 40%
    }

    .logo2 {
        bottom: 0px;
        position:unset;
        margin: 10px 0;
    }
}