@import url('https://fonts.googleapis.com/css2?family=Familjen+Grotesk:wght@700&family=Roboto+Mono&family=Roboto:wght@300;400;700&display=swap');
#root{
  width: 100%;
  background-color: rgb(255, 255, 255);
  transition: background-color 0.5s ease;
  font-family: 'Roboto Mono', monospace;
}
#root.dark {
  background-color: black;
}

.app {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app a {
  display: flex;
  justify-content: center;
}

header {
  width: 93vw;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

h1{
  display: flex;
  font-family: 'Roboto Mono', monospace;
  margin: 0;
}

.logo {
  width: 80px;
}

nav{
  display: flex;
  display: flex;
  padding: 0;
  margin: 0;
}
nav ul {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  list-style-type: none;
  padding: 0;
}

nav ul li {
padding: 0;
margin: 0;
font-size: 30px;
font-weight: 700;
font-family: 'Familjen Grotesk', sans-serif;
margin-left: 30px;
color: #888;
transition: color 2s;
}

nav ul li:hover{
  color: black
}

a{
  padding: 0;
  margin: 0;
  color: black;
  text-decoration: none;
  display: flex;
}

@media screen and (max-width: 1300px) {
  nav{
    display: none;
  }
  h1{
    font-size: x-large;
  }
  .logo {
    width: 50px;
  }
}