.NavMobile{
    width: 100%;
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 15px;
}

.NavMobileElements{
    display: flex;
    max-height: 0;
    visibility: hidden;
    overflow: hidden;
    opacity: 0;
    transition: max-height 2s ease, visibility 1.5s linear, opacity 1.5s;
    padding-left: 25px;
}

.NavMobileElements.active{
    max-height: 1000px; 
    visibility: visible;
    opacity: 1;
}

.NavMobileElements ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.NavMobileElements ul li {
    padding-bottom: 10px;
    font-weight: 700;
    font-family: 'Familjen Grotesk', sans-serif;
}

.rotate90 {
    transform: rotate(90deg);
    width: 100%;
    height: 100%;
    transform-origin: center;
  }

@media screen and (max-width: 1300px) {
    .NavMobile{
      display: flex;
    }
  }