.Project {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

.mainImg {
    width: 100%;
}

.ProjectData {
    display: flex;
    font-size: 18px;
}

.ProjectDescription {
    display: flex;
    width: 70%;
    margin: 0;
    text-align: justify;
    font-size: 18px;
}

.ProjectImages {
    display: flex;
    width: 80%;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

.ProjectImage {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}


@media screen and (max-width: 1300px) {

    .Project h2 {
        font-size: 20px;
    }

    .ProjectData {
        font-size: 13px
    }
    .ProjectDescription {
        font-size: 16px;
    }
}